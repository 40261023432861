.tasks-container {
    position: relative;
    align-items: center;
    flex: 1;
    overflow-y: auto;
  }
  
  .task-modal {
    position: absolute;
    top: 3.5%;
    left: 18%;
    transform: translate(-50%, -50%);
    display: grid;
    flex-direction: column;
    align-items: left;
    padding: 5px;
    background-color: #f8f8f8;
    border-radius: 10px;
    z-index: 1000;
    margin-left: 5vw;
  }
  
  .task-modal form {
    display: flex;
    justify-content: space-between;
    align-items: left;
    margin-left: 4vw;
  }
  
  .task-modal form input {
    margin-right:5px; /* Adjust this value as needed */
    margin-left: 5px; /* Adjust this value as needed */
    padding: 5px;
    
  }
  
  .task-modal form select {
    padding: 10px;
  }
  .tasks-container button {
    margin-left: 10px;
    align-self: flex-start;
    padding: 10px;
  }

  .tasks-tasks.selected {
    box-shadow: 0 0 20px #39ff14;
    transform: scale(1.15); /* Makes the task grow slightly */
    transition: transform 0.3s ease; /* Smooth transition */
  }