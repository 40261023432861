@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body {
  display: flex;
  flex-direction: column;
  height: 100vh;
  margin: 0;
  padding: 0;
}

.nodegraph-container {
  position: fixed;
  margin-left: 20vw;
  top: 20vh;
  height: 100vh;
  border: 2px solid white;
  background-color: black;
  border-radius: 15px;
}