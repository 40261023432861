/* Profile.css */

.profile-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .image-container {
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .profile-title {
    margin-top: 20px;
  }
  
  .profile-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 40px;
  }
  
  .profile-form label {
    margin-bottom: 10px;
  }
  
  .profile-form input {
    margin-left: 10px;
  }
  
  .profile-form input[type="email"] {
    background-color: #d3d3d3;
  }