/* Dashboard.css */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body {
  display: flex;
  flex-direction: column;
  height: 100vh;
  margin: 0;
  padding: 0;
}

  .profile-icon {
    border-radius: 50%;
    width: 50px;
    height: 50px;
  }

  .misc-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 20vw;
    height: 20vh;
    border: 2px solid white;
    background: linear-gradient(to right, #FF1493 0%, #0000FF 100%);
  }

  .tasks-container {
    left: 0;
    width: 20vw;
    height: 60vh;
    margin-top: 20vh;
    
    border: 2px solid white;
    background: linear-gradient(to right, #FF1493 0%, #0000FF 100%);
    
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden
  }
  
  .tasks-tasks {
    flex: 1;
    width: 20vw;
    border: 2px solid white;
    color: black;
    background-color: transparent; 
  }

  .selection-container {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 65vw;
    height: 20vh;
    border: 2px solid white;
    background-color: rgb(0, 0, 0);
    border-radius: 15px;
  }

  .objective-container {
    position: fixed;
    top: 0;
    right: 0;
    width: 80vw;
    height: 20vh;
    border: 2px solid white;
    background: linear-gradient(to right, #0000FF 85%, #FF1493 100%);
    border-radius: 15px;
  }


  .chat-modal-prompt {
    position: fixed;
    left: 0;
    width: 15vw;
    height: 15vh;
    margin-left: 20vw;
    margin-bottom: 2vh;
    border: 2px solid white;
    background-color: black;
    border-radius: 15px;
  }

  .chat-modal-response-yes {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 7.5vw;
    height: 5vh;
    margin-left: 20vw;
    border: 2px solid white;
    border-radius: 15px;
    background-color: #39FF14;
    border: none;
    color: white;
    font-size: 1.5em;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .chat-modal-response-no {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 7.5vw;
    height: 5vh;
    margin-left: 27.5vw;
    border: 2px solid white;
    border-radius: 15px;
    background-color: #953232;
    border: none;
    color: white;
    font-size: 1.5em;
    display: flex;
    justify-content: center;
    align-items: center;
  }