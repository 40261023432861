@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body {
  display: flex;
  flex-direction: column;
  height: 100vh;
  margin: 0;
  padding: 0;
}

.chat-history {
    position: fixed;
    left: 0;
    width: 20vw;
    height: 15vh;
    margin-bottom: 2vh;
    border: 2px solid white;
    background-color: black;
    font-size: 0.8em;
    border-radius: 15px;
    overflow-y: auto;
    display: flex;
    flex-direction: column-reverse;
  }

  .chat-input {
    position: fixed;
    bottom: 0;
    left: 0;
    height: 5vh;
    width: 20vw;
    font-size: 0.8em;
    padding: 1em;
    border: 2px solid white;
    background-color: black;
    border-radius: 15px;
  }
  
  .chat-input textarea {
    width: 90%;
    height: auto;
    resize: none;
    border-radius: 100px;
    
  }
  
  .submit-button {
    margin-left: 17.75vw;
    margin-bottom: 1vh;
    height: 3vh;
    width: 2vw;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: #39FF14;
    border: none;
    border-radius: 15px;
    color: white;
    font-size: 1.5em;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .message-from-user-76,
  .message-from-other-users {
    width: 100%; /* Make the message containers take up the full width of the chat area */
    padding: 10px;
    border-radius: 10px;
  }
  
  .message-from-user-76 {
    background-color: green;
    text-align: left; /* Align text to the left for messages from user 76 */
  }
  
  .message-from-other-users {
    background-color: black;
    text-align: right; /* Align text to the right for messages from other users */
  }

  .major-chat-component {
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 10px;
    background-color: #f8f8f8;
  }